import React from 'react'
import { MetaData } from '../components/common/meta'
import { Link } from 'gatsby'
import { Layout } from '../components/common'
import SVG from 'react-inlinesvg'

const NotFoundPage = ({ location }) => (
    <>
        <MetaData
            title={'404 Page not found'}
            location={location}
            type="website"
            />
        <Layout>
            <article className="for-o-for">
                <h1 className="visually-hidden">Error 404: Page not found</h1>
                <div className="container">
                    <p className="ee-logo"><SVG src='/images/logos/EE_logoBW.svg' /></p>
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <img src="/images/404.jpg" alt="" />
                        </div>
                        <div className="col-md-6 text-align-center">
                            <Link to="/" className="btn btn__theme my-5">ΕΠΙΣΤΡΟΦΗ ΣΤΗΝ ΑΡΧΙΚΉ</Link>
                            <p>Το Διάγραμμα Venn είναι μια απεικόνιση συνόλων. Η πιο συνήθης μορφής του διαγράμματος Venn  χρησιμοποιείται στην εκπαίδευση, σε στατιστικές μελέτες, σε υποθετικά σενάρια και όταν προσπαθείτε να επισκεφτείτε ιστοσελίδες που δεν υπάρχουν, καθώς χρησιμεύει σαν μνημονικός κανόνας για σημαντικές σχέσεις μεταξύ των διάφορων πράξεων των συνόλων.</p>
                        </div>
                    </div>
                </div>
            </article>
        </Layout>
    </>
)

export default NotFoundPage
